html,
body {
    padding: 0;
    margin: 0;
    font-family: 'neue-haas-grotesk-display', -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

a,
textarea,
select,
input,
button {
    font-family: inherit;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
    --scrollbar-width: 0.5em;
    --scrollbar-margin: 2px;
    width: calc(var(--scrollbar-width) + var(--scrollbar-margin));
    border-radius: var(--scrollbar-width);
    border-left: var(--scrollbar-margin) transparent solid;
    border-right: var(--scrollbar-margin) transparent solid;
    background-clip: padding-box;
}

*::-webkit-scrollbar {
    background-color: transparent;
}

:root {
    --pink: #e44592;
    --error: #ff4400;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--gray);
}

.Toastify__toast-container {
    width: max-content !important;
    max-width: 100vw;
}
